.filter-bar-component-container {
  display: flex;
  align-items: center;
  border-right: 1px solid rgba(186, 174, 229, 0.2);
  border-left: 1px solid rgba(186, 174, 229, 0.2);

  .lmt-filter-bar-date-picker {
    background-color: transparent;
    border: none;

    .ant-picker-input > input {
      border-radius: 0 !important;
      color: #baaee5;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      border: none;
    }

    .ant-picker-separator {
      border-right: 1px solid rgba(186, 174, 229, 0.2);
      height: 35px;
      color: transparent;
      margin-left: -20px;
    }

    .ant-picker-suffix {
      order: -1;
      margin-right: 10px;
      font-size: 15px;
      color: #baaee5;
    }

    .ant-picker-active-bar {
      margin-left: 31px !important;
      background-color: #baaee5 !important;
    }
  }
}
