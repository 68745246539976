.epsilon-menu-dropdown {
  width: 270px;
  color: #baaee5;
  display: flex;
  margin-left: 15px;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    color: #baaee5;
  }

  .epsilon-menu-dropdown-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 40px;

    .epsilon-menu-focus-styled {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-right: 15px;
      margin-left: 15px;

      .general-title-menu-dropdown {
        line-height: 15px;
        color: #f5f7fa;
        font-size: 12px;
        font-weight: 500;
      }

      .general-text-menu-dropdown {
        line-height: 15px;
        color: #baaee5;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
.ant-dropdown-menu {
  overflow: hidden;
  .ant-dropdown-menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.menu {
  padding: 0;
  .menu-item {
    &:last-child {
      .menu-link {
        border-bottom: none;
        text-decoration: none;
      }
    }
    .menu-link {
      display: flex !important;
      align-items: center !important;
      grid-gap: 5px !important;
      gap: 5px !important;
      border-bottom: 1px solid #e1e1e1;
      width: 95%;
      text-decoration: none;
    }
  }
}
