.public-layout {
  height: 100%;
  /*background-image: linear-gradient(224deg, #A8EB12 0%, #00BF72 32%, #008793 66%, #004D7A 100%, #000000 100%);*/

  /*background-image: linear-gradient(
    -45deg,
    #ecd93b 0%,
    #e1ae34 9%,
    #cd7130 77%,
    #a81624 100%,
    #000000 100%
  );*/
  background: #121547 !important;
  padding: 0px;

  .ant-layout-footer {
    background-color: transparent;
    display: flex;
    align-items: center;

    a,
    .copyright {
      font-family: 'Harmattan', sans-serif;
      font-size: 15px;
      color: #fff;
      opacity: 0.86;
    }

    span {
      color: #fff;
      font-size: 15px;
      opacity: 0.86;
    }
  }
}
