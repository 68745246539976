.selected-filter-bar-component {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;

    .title-selected-filters-bar-component {
        color: #BAAEE5;
        font-size: 14px;
        font-weight: 500;
    }

    .tag-style-filters-bar-component {
        background-color: rgb(95, 43, 135);
        color: #FFF;
        border: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .ant-tag-close-icon {
            color: #FFF;
            align-self: center;
        }
    }
}