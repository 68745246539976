.ant-message-notice-content {
  background-color: rgba(0, 0, 0, 0.54) !important;
  border-radius: 16px !important;

  .ant-message-custom-content.ant-message-error {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .anticon.anticon-close-circle {
    top: 1px;
  }

  span {
    color: #fff;
    margin-top: -3px;
    pointer-events: none;
    user-select: none;
  }
}

.ant-table {
  &.ant-table-bordered {
    .ant-table-container {
      border-radius: 16px;
      //border: 1px solid #e9e9e9 !important;
      border: 1px solid rgba(0, 0, 0, 0.09) !important;
      overflow: hidden;
      position: relative;
      padding-left: 5px;
      padding-right: 5px;
    }

    thead > tr > th {
      border: none;
      border-bottom: solid 1px rgba(0, 0, 0, 0.09);
      font-weight: 700;
      color: #555;
      background-color: none;
    }

    tbody > tr > td {
      border: none;

      &:last-child {
        border: none !important;
      }
    }

    .ant-table-placeholder > td {
      height: 500px;
    }
  }

  .ant-table-thead::before {
    content: '';
    background-color: #fafafa;
    position: absolute;
    height: 46px;
    width: 40px;
    display: block;
    margin-left: -5px;
    top: 0;
  }

  .ant-table-thead::after {
    content: '';
    background-color: #fafafa;
    position: absolute;
    height: 46px;
    width: 40px;
    display: block;
    right: -5px;
    top: 0;
  }

  .c-actions {
    max-width: 48px;
    padding: 0px;
  }

  .actions {
    text-align: center;
    padding: 0px;
    max-width: 48px !important;

    .btn-action {
      position: relative;
    }

    .btn-action.inactive {
      opacity: 0.4;
      cursor: default;
    }
  }

  a {
    max-width: 48px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .icon-action {
    display: block;
    cursor: pointer;
    max-width: 48px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: relative;
  }

  .center {
    text-align: center;
  }
}

.action-tooltip {
  top: -32px !important;
}

.help-tooltip {
  top: 45px !important;
}

.ant-input {
  line-height: 1.2;

  &input {
    border-radius: 0 !important;
  }

  .ant-input-suffix {
    border-left: solid 1px #d9d9d9;
    margin: 0;
    padding-left: 10px;
  }
}

.ant-input-affix-wrapper > input.ant-input {
  border-radius: 0;
}

.ant-modal-content {
  border-radius: 12px !important;

  .ant-modal-header {
    border-radius: 12px 12px 0 0;
  }
}

.ant-checkbox-group {
  padding-top: 14px;

  .ant-col {
    margin-top: 14px;
    margin-bottom: 10px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.ant-steps-vertical .ant-steps-item {
  height: 80px;
}

.ant-btn > .ant-btn-loading-icon {
  margin-right: 10px;

  .anticon {
    padding-right: 0;
  }
}

.ant-select-tree-checkbox-inner,
.ant-checkbox-inner {
  border-radius: 2px !important;
}

.react-grid-item.react-grid-placeholder {
  background-color: rgba(0, 191, 114, 0.35) !important;
  border-radius: 12px;
}

.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 1px solid #b5a9da;
}
