.epsilon-layout {
}

.epsilon-content {
  background-color: #f5f7fa;
  min-height: 80vh;
  //padding: 1.5%;
}

.epsilon-footer {
  position: relative;
  width: 100%;
  bottom: 0;
}
