.profile-menu {
  .profile-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    h4 {
      margin-top: 12px;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 5px;
    }

    h5 {
      font-size: 12px;
      color: #5f6368;
    }
  }  

  .row-container-logo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #D9DCDF;
    border-bottom: 1px solid #D9DCDF;
    padding-top: 15px;
    padding-bottom: 15px;

    .title-row-container-logo {
      color: #3B1D52;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
    }

    .image-row-container-logo {
      width: 25px;
      height: 23px;
    }
  }

  .admin-profile-text {
    color: #3B1D52;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    border-bottom: 1px solid #D9DCDF;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
  }

  .palette-color-selection-container {
    height: 150px;
    padding-top: 15px;
    padding-bottom: 15px;

    .text-palette-color {
      color: #3B1D52;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      margin-bottom: 10px;
    }

    .text-info-palette-color {
      font-size: 12px;
      font-weight: 400;
      color: #818181;
      line-height: 14px;
      margin-top: 10px;
    }
  }

  .on-logout-container {
    border-top: 1px solid #D9DCDF;
    color: #3B1D52;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    padding-top: 15px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
  }

  .ant-card {
    border-radius: 10px;
  }

  .ant-menu {
    border: none;
    margin-top: -5px;

    .ant-menu-item {
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;

      .anticon {
        font-size: 16px;
        margin-right: 20px;
      }
    }
  }

  .ant-btn {
    border-radius: 24px;
    margin: 16px auto 0;
    width: 80%;
    display: block;
  }

  .legals-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 15px;

    .ant-btn {
      margin-top: 0;
    }

    a {
      font-size: 12px;
      color: #5f6368;
      opacity: 0.8;
    }

    span.dot-divider {
      min-width: 3px;
      min-height: 3px;
      border-radius: 1.5px;
      background-color: #5f6368;
      opacity: 0.5;
    }
  }
}

.profile-dropdown {
  .ant-popover-inner {
    border-radius: 10px;
  }

  .ant-popover-arrow {
    display: none;
  }
}