.language-switcher {
    
    .language-button {
        margin-right: 20px;
        background: inherit !important;
        border: 0px !important;

        img {
            border-radius: 10px;
        }
    }
}