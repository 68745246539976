@import "~antd/dist/antd.less";
@import './theme.less';
@import './overrides.less';
@import '~animate.css';
@import '~react-grid-layout/css/styles.css';
@import '~react-resizable/css/styles.css';

body {
  font-family: 'Cairo', sans-serif;
  -webkit-font-smoothing: antialiased;
  /*overflow: hidden;*/
  min-width: 1024px;
}

.title {
  font-family: 'Harmattan', sans-serif;
}

.flex {
  display: flex;

  &.flex-horizontal {
    flex-direction: row;
  }
}

.flex-1 { flex: 1 1; }
.flex-2 { flex: 2 1; }
.flex-3 { flex: 3 1; }
.flex-4 { flex: 4 1; }
.flex-5 { flex: 5 1; }
.flex-6 { flex: 6 1; }
.flex-7 { flex: 7 1; }

.flex-horizontal.flex-center {
  justify-content: center;
  align-items: center;
}

.toolbar {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  min-height: 30px;
  align-items: center;

  .table-view-switcher {
    background-color: #01988a;

    &.ant-switch-checked {
      background-color: #118098;
    }
  }

  .ant-btn-icon-only {
    color: #666;
  }
}

.blocker-loading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #FFF;
  z-index: 9000;
  justify-content: center;
  align-items: center;
  display: flex;
}

.loading-container {
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-spin-text {
    margin-top: 8px;
  }
}

.chart-tooltip {
  z-index: 10000;
  background-color: rgba(51, 51, 51, 0.9);
  color: #FFF;
  font-size: 12px;
  border-radius: 8px;
  box-shadow: none;
  padding: 12px 16px;

  .data {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .dot {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      margin-right: 5px;
    }

    .value {
      font-family: 'Cairo', sans-serif;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 15px;

      span:first-child {
        margin-right: 5px;
      }
    }
  }
}

.ant-picker-range-arrow {
  margin-left: 28px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #3A1B52;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: none
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #501C78;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range {
  color: #FFF;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before, .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after, .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  background: #BAAEE5;
}